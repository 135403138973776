<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                      <h5 class='complain-title'>{{ $t('germConfig.germplasm_receiver_info') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('germConfig.receiver_name_en')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.receiver_name  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('germConfig.receiver_name_bn')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.receiver_name_bn  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('germConfig.receiver_code')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ $n(data.receiver_code)  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.phone_no')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize"> {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.phone_no, { useGrouping: false })  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.email')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark">{{ data.email  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('germConfig.tin')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ $n(data.tin_no)  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.address_en')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.address  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.address_bn')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.address_bn  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.remarks_en')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.remarks  }}</p>
                      </b-col>
                       <b-col lg="3" sm="12">
                        <p class="text-dark">{{$t('globalTrans.remarks_bn')}} : </p>
                      </b-col>
                      <b-col lg="3" sm="12">
                         <p class="text-dark capitalize">{{ data.remarks_bn  }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getReceiverInfoData()
      this.data = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.view'),
      data: {
        id: '',
        details: []
      },
      cropNameList: [],
      loader: false,
      search: {
        crop_name_id: 0
      }
    }
  },
  methods: {
    getReceiverInfoData (id) {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
