<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6">
                        <ValidationProvider name="Receiver Name (En)" vid="receiver_name" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="receiver_name"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.receiver_name_en')}}<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="receiver_name"
                              v-model="data.receiver_name"
                              :placeholder="$t('germConfig.receiver_name_en')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6">
                        <ValidationProvider name="Receiver Name (En)" vid="receiver_name_bn" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="receiver_name_bn"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.receiver_name_bn')}}  <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="receiver_name_bn"
                              v-model="data.receiver_name_bn"
                              :placeholder="$t('germConfig.receiver_name_bn')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6">
                        <ValidationProvider name="Receiver Code" vid="receiver_code">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="receiver_code"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.receiver_code')}}
                            </template>
                            <b-form-input
                              id="receiver_code"
                              v-model="data.receiver_code"
                              :placeholder="$t('germConfig.receiver_code')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6">
                        <ValidationProvider name="Phone No" vid="phone_no" rules ="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="phone_no"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.phone_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="phone_no"
                               type="number"
                              @input="acceptNumber"
                              v-model="data.phone_no"
                              :placeholder="$t('globalTrans.phone_no')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6">
                        <ValidationProvider name="Email" vid="email" rules="email">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.email')}}
                            </template>
                            <b-form-input
                              id="email"
                              v-model="data.email"
                              :placeholder="$t('globalTrans.email')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6">
                        <ValidationProvider name="Tin No" vid="tin_no">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="tin_no"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.tin')}}
                            </template>
                            <b-form-input
                              id="tin_no"
                              v-model="data.tin_no"
                              :placeholder="$t('germConfig.tin')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Nid" vid="nid" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.nid')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                             type="number"
                              id="nid"
                              v-model="data.nid"
                              :placeholder="$t('germConfig.nid')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Trade License No" vid="trade_license_no" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="trade_license_no"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.trade_licence_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                             type="number"
                              id="trade_license_no"
                              v-model="data.trade_license_no"
                              :placeholder="$t('germConfig.trade_licence_no')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Address (En)"  vid="address">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="address"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germConfig.address_en')}}
                            </template>
                              <b-form-textarea
                                id="address"
                                v-model="data.address"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.address')"
                                style="min-height:100px"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Address Bn"  vid="address_bn">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="address_bn"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.address_bn')}}
                            </template>
                              <b-form-textarea
                                id="address_bn"
                                v-model="data.address_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.address_bn')"
                                style="min-height:100px"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (En)"  vid="remarks">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remarks"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.remarks_en')}}
                            </template>
                              <b-form-textarea
                                id="remarks"
                                v-model="data.remarks"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.remarks')"
                                style="min-height:100px"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks Bn"  vid="remarks_bn">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remarks_bn"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('globalTrans.remarks_bn')}}
                            </template>
                              <b-form-textarea
                                id="remarks_bn"
                                v-model="data.remarks_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :placeholder="$t('globalTrans.remarks_bn')"
                                style="min-height:100px"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { receiverInfoStore, receiverInfoUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getReceiverInfoData()
      this.data = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      data: {
        id: '',
        receiver_name: '',
        receiver_name_bn: '',
        receiver_code: '',
        phone_no: '',
        email: '',
        tin_no: '',
        nid: '',
        trade_license_no: '',
        address: '',
        address_bn: '',
        remarks: '',
        remarks_bn: '',
        status: '1'
      }
    }
  },
  methods: {
    getReceiverInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    acceptNumber () {
      var x = this.data.phone_no.replace(/\D/g, '').match(/^(01[356789])(\d{8})$/)
        if (!x) {
            this.data.phone_no = ''
        }
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.data.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${receiverInfoUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, receiverInfoStore, this.data)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
